import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import adidas from '../../resources/images/logos/clients_logos/adidas.svg'
import Arity from '../../resources/images/logos/clients_logos/Arity.svg'
import Daimler from '../../resources/images/logos/clients_logos/Daimler.svg'
import Generali from '../../resources/images/logos/clients_logos/Generali.svg'
import HealthFirst from '../../resources/images/logos/clients_logos/HealthFirst.svg'
import Pfizer from '../../resources/images/logos/clients_logos/Pfizer.svg'
import { StaticImage } from 'gatsby-plugin-image'

const logos = ({ width }) => {
    // console.log(width)
    return (
        <Fragment>
            <div
                className='container-2 logos-section mobile-padding-reset'
                style={{ overflow: 'hidden', padding: '10px 0' }}>
                <div className='text-center mb-2'>
                    <p className='pb-3'>
                        <a className='h4-like fw-600' href='/customers/'>
                            HUNDREDS OF CUSTOMERS LOVE & TRUST LENSES
                        </a>
                    </p>
                </div>
                <div className={'logos-main-container'}>
                    <div className={'logos-desktop'}>
                        <StaticImage src={'../../resources/images/logos.svg'} alt={'logos'} />
                    </div>
                    <div className='row mx-3 mobile-padding-reset logos-container logos-responsive'>
                        <div className=''>
                            <a href='/customers/'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/Arity.svg'
                                    src={Arity}
                                    placeholder='tracedSVG'
                                    alt='Arity'
                                />
                            </a>
                        </div>
                        <div className=' '>
                            <a href='/customers/' className='w-100 d-block'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/adidas.svg'
                                    src={adidas}
                                    placeholder='tracedSVG'
                                    alt='Daimler'
                                />
                            </a>
                        </div>
                        <div className=''>
                            <a href='/customers/'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/Daimler.svg'
                                    src={Daimler}
                                    placeholder='tracedSVG'
                                    alt='Daimler'
                                />
                            </a>
                        </div>
                        <div className=''>
                            <a href='/customers/'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/Pfizer.svg'
                                    src={Pfizer}
                                    placeholder='tracedSVG'
                                    alt='Pfizer'
                                />
                            </a>
                        </div>

                        <div className=''>
                            <a href='/customers/'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/Generali.svg'
                                    src={Generali}
                                    placeholder='tracedSVG'
                                    alt='Generali'
                                />
                            </a>
                        </div>
                        <div className='' style={{ marginRight: '15px' }}>
                            <a href='/customers/'>
                                <img
                                    className={`${width ? `w-${width}` : 'w-75'}  zoom-on-hover `}
                                    // src='../../resources/images/logos/clients_logos/HealthFirst.svg'
                                    src={HealthFirst}
                                    placeholder='tracedSVG'
                                    alt='HealthFirst'
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className="text-center pb-5 ">
        <a className="link-text" href="/customers/">
          Read success stories &rarr;{' '}
        </a>
      </div> */}
            </div>
        </Fragment>
    )
}

export default logos

logos.propTypes = {
    width: PropTypes.number,
}
